/* Font Imports from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto:wght@400&display=swap');

/* Container for the Cart page */
.cart-container {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 60%; /* Limit max-width for larger screens */
    max-width: 1200px;
    font-family: 'Roboto', sans-serif; /* Apply Roboto font for general text */
}

/* Title of the cart page */
.cart-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif; /* Use Poppins for headings */
    font-weight: 600;
}

/* Container for cart items */
.cart-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

/* Individual cart item card */
.cart-item-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 7px;
    overflow: hidden;
    width: 100%;
    justify-content: space-around;
    transition: transform 0.2s, box-shadow 0.2s;
}

.cart-item-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Cart item image */
.cart-item-card img {
    width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

/* Container for cart item details */
.cart-item-details {
    margin-left: 1rem;
    font-family: 'Roboto', sans-serif; /* Ensure description and price use Roboto */
}

/* Item title */
.cart-item-details h3 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333; /* Dark color for the title */
}

/* Item description with line clamp */
.cart-item-details p {
    margin: 0.5rem 0;
    color: #555; /* Lighter text for description */
    font-size: 1rem;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2; /* Clamp to 2 lines */
    -webkit-box-orient: vertical; /* Set the box orientation to vertical */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Show ellipsis when the text is truncated */
}

/* Cart item price */
.cart-item-price {
    font-weight: bold;
    color: #333;
}

/* Container for item actions */
.cart-item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

/* Quantity buttons */
.cart-quantity-button {
    background-color: #135fb0;
    color: #fff;
    border: none;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.cart-quantity-button svg {
    font-size: 1.25rem;
}

/* Remove button */
.remove-button {
    background-color: #ff5722;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.remove-button svg {
    font-size: 1.25rem;
}

/* Buy Now button */
.buy-now-button {
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 10px;
    width: 100%; /* Full width on small screens */
    max-width: 160px;
}

.buy-now-button:hover{
    background-color: #e64a19; /* Darker shade on hover */
    transform: translateY(-2px);
}

.buy-now-button svg {
    font-size: 1.25rem;
}

/* Checkout button */
.checkout-button {
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 10px;
    width: 30%; /* Full width on small screens */
}

.checkout-button:hover{
    background-color: #e64a19; /* Darker shade on hover */
    transform: translateY(-2px);
}

/* Responsive design for small screens */
@media (max-width: 550px) {
    .cart-container {
        width: 100%;
        padding: 0.7rem; /* Adjust padding for smaller screens */
    }

    /* Individual cart item card */
    .cart-item-card {
        flex-direction: row; /* Change to column layout for better responsiveness */
        align-items: center; /* Center align items */
        text-align: center; /* Center text for better alignment */
    }

    /* Cart item image */
    .cart-item-card img {
        max-width: 100%; /* Ensure image fits the card */
    }

    /* Container for cart item details */
    .cart-item-details {
        margin: 1rem 0;
    }

    /* Item actions */
    .cart-item-actions {
        flex-wrap: wrap; /* Allow actions to wrap on smaller screens */
        gap: 0.5rem;
    }

    /* Quantity buttons */
    .quantity-button,
    .remove-button,
    .buy-now-button {
        font-size: 0.875rem; /* Adjust button font size */
    }

    /* Checkout button */
    .checkout-button {
        font-size: 1rem; /* Adjust checkout button font size */
    }
}

/* Responsive design for medium screens */
@media (max-width: 800px) {
    .cart-container {
        width: 90%; /* Slightly reduce width for medium screens */
    }
}

/* Login invalid container */
.cartLoginInvalid {
    padding: 2rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

/* Shop Now button */
.Shop-Now-Button {
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 10px;
    width: 100%; /* Full width on small screens */
}

.Shop-Now-Button:hover {
    background-color: #e64a19; /* Darker shade on hover */
    transform: translateY(-2px);
}

/* Discount styling */
.total-discount {
    text-decoration: line-through;
    margin-right: 10px;
}
