/* Contact.css */
.contact-container {
    padding: 40px;
    max-width: 900px;
    margin: 30px auto;
    background-color: rgb(202, 202, 252); /* Light purple background */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    animation: fadeIn 1.5s ease-out;
    border: 1px solid #e0f7fa; /* Light blue border for a calming effect */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.contact-header {
    font-size: 2.4em;
    text-align: center;
    margin-bottom: 25px;
    color: #009688; /* Calming teal color */
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-out;
}

.contact-subheader {
    font-size: 1.8em;
    color: #009688;
    margin-top: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #a7d8d0; /* Soft underline */
    font-weight: 600;
    animation: bounceIn 1.2s ease-out;
}

.contact-intro,
.contact-methods,
.contact-address {
    font-size: 1.1em;
    line-height: 1.7;
    color: #4a4a4a; /* Darker gray for better readability */
    margin-bottom: 20px;
    transition: color 0.3s ease;
}

.contact-list {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 10px;
}

.social-media-list {
    list-style-type: none;
    padding-left: 20px;
}

.contact-link {
    color: #009688;
    cursor: pointer;
    text-decoration: underline;
}

.contact-link:hover {
    color: #00796b; /* Hover effect */
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceIn {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }
    60% {
        transform: translateY(15px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .contact-container {
        padding: 30px;
        margin-bottom: 5rem;
    }

    .contact-header {
        font-size: 2em;
    }

    .contact-subheader {
        font-size: 1.6em;
    }

    .contact-intro,
    .contact-methods,
    .contact-address {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .contact-container {
        padding: 20px;
    }

    .contact-header {
        font-size: 1.7em;
    }

    .contact-subheader {
        font-size: 1.5em;
    }

    .contact-intro,
    .contact-methods,
    .contact-address {
        font-size: 0.95em;
    }
}
