.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('../../Assets/bg_image-FF.jpg') no-repeat center center;
  background-size: cover;
}

.auth-form {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative; /* Add position relative for the overlay */
}

.auth-form h1 {
  margin-bottom: 1rem;
  text-align: center;
}

.auth-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.auth-form select{
  margin-bottom: 15px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
}

.Signup-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #f57c00; /* Bright Orange */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem; /* Add margin for spacing */
}

.Signup-button:hover {
  background-color: #e65100; /* Darker Orange */
}

.separator {
  border: none;
  border-top: 1px solid #ddd;
  margin: 1rem 0;
}

.google-signup-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4285F4; /* Google Blue */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.google-signup-button:hover {
  background-color: #357ae8; /* Darker Google Blue */
}

.google-signup-button:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://www.google.com/images/branding/googlelogo/2x/googlelogo_light_color_272x92dp.png'); /* Google logo */
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}

@media (max-width: 600px) {
  .auth-form {
      padding: 1.5rem;
      max-width: 90%;
  }
}

/* New CSS for loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-overlay img {
  width: 50px;
  height: 50px;
}
