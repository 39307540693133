/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto:wght@400&display=swap');

/* General Reset */

/* Modal overlay */
.modal-overlay {
    position: fixed;
    padding: 2rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1005;
}

/* Modal content */
.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    max-width: 70%;
    max-height: 80vh;
    overflow: scroll;
    scrollbar-width: 0;
    font-family: 'Poppins', sans-serif; /* Apply Poppins for the modal content */
    position: relative;
}

/* Hide scrollbar for WebKit browsers */
.modal-content::-webkit-scrollbar {
    display: none;
}

/* Modal card for multiple items */
.modal-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    width: 100%;
}

/* Limit description to 2 lines and truncate with ellipsis */
.modal-card-details p {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    color: #555;
}


/* Modal close button */
.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
    transition: color 0.3s ease;
    z-index: 1010;
}

.modal-close:hover {
    color: #f57c00;
}

/* Product card in modal */
.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #fafafa;
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.product-card img {
    width: 120px;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.product-card h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
}

.product-card p {
    margin: 0.5rem 0;
    font-family: 'Roboto', sans-serif; /* Apply Roboto for product description */
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
}

.product-size {
    margin: 15px 0;
}

.product-size label {
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
}

.product-size select {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
}

.product-size select:focus {
    border-color: #007bff;
    outline: none;
}

.product-size select option {
    padding: 10px;
}

/* Quantity controls */
.quantity-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.quantity-buttons {
    background-color: #135fb0;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 1.4rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quantity-buttons:hover {
    background-color: #074c96;
}

.quantity-buttons:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

/* Product quantity */
.product-card .quantity {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

/* Input fields */
.inputText {
    width: 100%;
    padding: 10px;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.inputText:focus {
    border-color: #135fb0;
    outline: none;
}

/* Proceed button */
.proceed-button {
    background-color: #ff5722;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1.25rem;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 1.5rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.proceed-button:hover {
    background-color: #e64a19;
    transform: translateY(-2px);
}

/* Total amount display */
.total-amount {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: #333;
}

/* Preorder message */
.preorder-message {
    background-color: #ff9800;
    color: white;
    padding: 12px 18px;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    animation: fadeIn 1s ease-out;
}

/* Preorder icon */
.preorder-icon {
    font-size: 1.5rem;
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content {
        padding: 1.2rem;
        max-width: 90%;
        max-height: 70vh;
    }

    .modal-close {
        font-size: 1.4rem;
    }

    .product-card img {
        width: 100px;
    }

    .product-card h4 {
        font-size: 1.2rem;
    }

    .proceed-button {
        font-size: 1.1rem;
    }

    .preorder-message {
        font-size: 1rem;
    }

    .preorder-icon {
        font-size: 1.4rem;
    }
}

/* Small screen adjustments below 450px */
@media (max-width: 450px) {
    .modal-content {
        padding: 1rem;
        max-width: 90%;
        max-height: 80vh;
    }

    .modal-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        width: 100%;
    }

    .product-card {
        padding: 1rem;
        width: 100%;
    }

    .product-card img {
        width: 80px;
        height: auto;
    }

    .product-card h4 {
        font-size: 1.2rem;
    }

    .product-card p {
        font-size: 1rem;
    }

    .quantity-controls {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .quantity-buttons {
        padding: 6px 10px;
        font-size: 1.2rem;
    }

    .inputText {
        font-size: 1rem;
    }

    .proceed-button {
        padding: 10px 20px;
        font-size: 1.1rem;
    }

    .total-amount {
        font-size: 1.2rem;
    }

    .preorder-message {
        font-size: 0.9rem;
        flex-direction: column;
    }

    .preorder-icon {
        font-size: 1.3rem;
    }
}

/* Updates for screens below 400px */
@media (max-width: 400px) {
    /* Adjust the modal content for very small screens */
    .modal-content {
        padding: 0.8rem;
        max-width: 95%;
        max-height: 85vh;
    }

    /* Change layout of product card to stack image on top of details */
    .product-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
    }

    .product-card img {
        width: 80px;
        margin-bottom: 0.8rem; /* Slightly reduce space between image and text */
    }

    .product-card h4 {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }

    .product-card p {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .quantity-controls {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .quantity-buttons {
        padding: 6px 10px;
        font-size: 1.1rem;
    }

    .proceed-button {
        padding: 8px 16px;
        font-size: 1rem;
    }

    .total-amount {
        font-size: 1rem;
    }

    .preorder-message {
        font-size: 0.8rem;
        flex-direction: column;
    }

    .preorder-icon {
        font-size: 1.2rem;
    }
}

/* Loading overlay */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border-radius: 8px;
}

.loading-overlay img {
    width: 50px;
    height: 50px;
}

