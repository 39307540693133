/* src/App.css */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
}

/* App */
.App {
  text-align: center;
  height: 100%;
}
/* Loading Container */
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50vw; /* 50% of the viewport width */
  height: 50vw; /* Maintain square aspect ratio by setting height equal to width */
  max-width: 200px; /* Optional: Set a max width */
  max-height: 200px; /* Optional: Set a max height */
  min-width: 100px; /* Optional: Set a minimum width */
  min-height: 100px; /* Optional: Set a minimum height */
}

.loading-container img {
  width: 100%;  /* Image takes up the full width of the container */
  height: 100%; /* Image takes up the full height of the container */
  object-fit: contain; /* Ensures the image maintains its aspect ratio inside the square */
}


