.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('../../Assets/bg_image-FF.jpg') no-repeat center center;
  background-size: cover;
}

.auth-form {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative; /* Add position relative for the overlay */
}

.auth-form h1 {
  margin-bottom: 1rem;
  text-align: center;
}

.auth-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.auth-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #f57c00; /* Bright Orange */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem; /* Add margin for spacing */
}

.auth-form button:hover {
  background-color: #e65100; /* Darker Orange */
}

.separator {
  border: none;
  border-top: 1px solid #ddd;
  margin: 1rem 0;
}

@media (max-width: 600px) {
  .auth-form {
      padding: 1.5rem;
      max-width: 90%;
  }
}

/* New CSS for loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Adjust z-index as needed */
  border-radius: 8px; /* Match form border radius */
}

.loading-overlay img {
  width: 50px;
  height: 50px;
}