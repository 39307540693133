/* SuccessModal.css */
.success-modal-overlay {
    position: fixed;
    padding: 2rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 128, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

/* Success modal content */
.success-modal-content {
    position: sticky;
    background: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #333;
    font-family: 'Arial', sans-serif; /* Use a clean font */
}

.success-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
}

.success-modal-close:hover {
    color: #f57c00;
}

.success-modal-content h2 {
    color: #28a745; /* Green color for the title */
    margin: 0 0 1rem;
    font-size: 2rem; /* Increase font size for the title */
}

.success-modal-content p {
    margin: 0.5rem 0;
    font-size: 1.2rem; /* Slightly larger font for better readability */
    line-height: 1.5; /* Improve line spacing */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .success-modal-content {
        padding: 1rem;
    }

    .success-modal-close {
        font-size: 1.25rem;
    }

    .success-modal-content h2 {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }

    .success-modal-content p {
        font-size: 1rem; /* Smaller font size for smaller screens */
    }
}
